import { Button } from "@mui/material"

export const SearchButton = ({ handleclick, text }) => (
  <Button variant="contained" sx={{
    backgroundColor: "#7FD858", '&:hover': {
      backgroundColor: '#5db438', // Change the color for hover state
    },
  }} style={{
    height: '40px'
  }} onClick={handleclick} fullWidth>{text}</Button>
)