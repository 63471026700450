// @mui
import PropTypes from "prop-types";
import { alpha, styled } from "@mui/material/styles";
import { Card, Typography } from "@mui/material";
// utils
import { fShortenNumber } from "../../../utils/formatNumber";
// components
import Iconify from "../../../components/iconify";

// ----------------------------------------------------------------------

const StyledIcon = styled("div")(({ theme }) => ({
  margin: "auto",
  display: "flex",
  borderRadius: "50%",
  alignItems: "left",
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: "center",
  marginBottom: theme.spacing(3),
}));

// ----------------------------------------------------------------------

AppWidgetSummary.propTypes = {
  color: PropTypes.string,
  bottom: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  total: PropTypes.string.isRequired,
  postfix: PropTypes.string,
  sx: PropTypes.object,
};

export default function AppWidgetSummary({
  title,
  total,
  icon,
  bottom,
  postfix,
  color = "#F4F6F8",
  sx,
  ...other
}) {
  // console.log('color: ', color);
  return (
    <Card
      sx={{
        py: 2,
        px: 2,
        boxShadow: 0,
        textAlign: "left",
        // color: 'black',
        // color: (theme) => theme.palette[color].darker,
        // bgcolor: "#F4F6F8",
        bgcolor: color,
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        gap: '10px',
        // bgcolor: (theme) => theme.palette[color].lighter,
        ...sx,
      }}
      {...other}
    >
      <div>
        <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
          {title}
        </Typography>
        <div style={{ display: 'flex', justifyContent: 'left', gap: '10px', alignItems: 'center' }}>
          {/* <Typography variant="h3">{(total)}</Typography> */}
          <Typography variant="h3">{parseInt(total, 10).toLocaleString('en-US')}</Typography>
          <Typography variant="p">{postfix}</Typography>
        </div>
      </div>
      <div>
        <Typography variant="p">{bottom}</Typography>
      </div>
    </Card>
  );
}
