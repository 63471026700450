const SVG = (props) => {
  return (
    <svg className={props.className} onClick={props.onClick}>
      <use
        href={`${process.env.PUBLIC_URL}/assets/svg/icon-sprite.svg#${props.iconId}`}
      />
    </svg>
  );
};

export default SVG;
