// AddShop.jsx
import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { toast } from "react-toastify";
import { Alert, CircularProgress, MenuItem, Select } from "@mui/material";
import {
  CustomDialog,
  CustomDialogTitle,
  CustomDialogActions,
} from "../dialogstyle";
import locationlist from "./location.json";

import "react-toastify/dist/ReactToastify.css";

const localhost = process.env.REACT_APP_API_URL;

const AddShop = ({ open, setOpen, handleFetchData }) => {
  const [owner, setOwner] = useState([]);
  const [neterror, setNetError] = useState(false);
  const [loader, setLoader] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const handleFetchShopOwner = () => {
    axios
      .get(`${localhost}/shop-owners`)
      .then((res) => {
        console.log(res);
        setOwner(res.data);
      })
      .catch((err) => {
        console.log(err);
        // dispatch({
        //   type: "setShowSnackBar",
        //   payload: {
        //     show: true,
        //     message:
        //       err?.response?.data?.message || err?.message || "Network Error",
        //   },
        // });
      })
      .finally(() => {
        console.log("end");
      });
  };

  useEffect(() => {
    handleFetchShopOwner();
  }, []);

  const [formData, setFormData] = useState({
    shopOwnerId: 0,
    username: "",
    location: "Addis Ababa",
    password: "",
    confirmPassword: "",
  });

  const [error, setError] = useState({
    shopOwnerId: false,
    username: false,
    location: false,
    password: false,
    confirmPassword: false,
  });

  const handleSave = () => {
    // Validate required fields
    const requiredFields = ["shopOwnerId", "location", "username"];
    let isValid = true;
    setNetError(false);
    const newError = {};
    // console.log(formData.shopOwnerId);
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newError[field] = true;
        isValid = false;
      } else {
        newError[field] = false;
      }
    });

    if (formData.password.length < 6) {
      newError.password = "Password lenght must be more than 6";
      isValid = false;
    }

    // Validate password and confirm password match
    if (formData.password !== formData.confirmPassword) {
      newError.password = true;
      newError.confirmPassword = "password must match";
      isValid = false;
    }

    // If there are validation errors, update the state and return
    if (!isValid) {
      setError(newError);
      return;
    }
    setLoader(true);
    // Add your logic to handle the form data (e.g., send to an API)
    axios
      .post(`${localhost}/shop`, {
        username: formData.username,
        shopOwnerId: formData.shopOwnerId,
        location: formData.location,
        password: formData.password,
        // status: formData.status,
      })
      .then((res) => {
        console.log(res);
        toast.success("Added successfully...!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
        });
        handleFetchData();
        setFormData({
          shopOwnerId: 0,
          location: "",
          username: "",
          password: "",
          confirmPassword: "",
        });
        setOpen(false);
        handleClose();
      })
      .catch((err) => {
        console.log(err);
        setNetError(err.response?.data?.error || "Network error");
      })
      .finally(() => {
        setLoader(false);
      });

    console.log(formData);
  };

  const handleChange = (field) => (event) => {
    setFormData({ ...formData, [field]: event.target.value });

    // Clear error when the user types something
    setError({ ...error, [field]: false });
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <CustomDialogTitle>Create Shop and cashier</CustomDialogTitle>
      {neterror && <Alert severity="error">{neterror}</Alert>}
      <DialogContent>
        {/* Form Fields */}
        <p
          style={{
            margin: "0 5px",
            fontSize: "12px",
            color: error.shopOwnerId ? "red" : "black",
          }}
        >
          Shop Owner
        </p>
        <Select
          fullWidth
          defaultValue={0}
          onChange={handleChange("shopOwnerId")}
        >
          <MenuItem value={0} disabled>
            Choose Owner
          </MenuItem>
          {owner.reverse().map((own) => (
            <MenuItem value={own.id}>{own.name}</MenuItem>
          ))}
        </Select>
        <TextField
          margin="normal"
          label="Username"
          fullWidth
          variant="outlined"
          value={formData.username}
          error={error.username}
          onChange={handleChange("username")}
        />
        <p
          style={{
            margin: "0 5px",
            fontSize: "12px",
            color: error.shopOwnerId ? "red" : "black",
          }}
        >
          Location
        </p>
        <Select
          fullWidth
          value={formData.location}
          onChange={handleChange("location")}
          error={error.location}
          defaultValue={"Addis Ababa"}
        >
          {locationlist.map((item) => (
            <MenuItem value={item}>{item}</MenuItem>
          ))}
        </Select>
        <TextField
          margin="normal"
          label="Password"
          type="password"
          fullWidth
          variant="outlined"
          value={formData.password}
          error={error.password}
          helperText={error.password && error.password}
          onChange={handleChange("password")}
        />
        <TextField
          margin="normal"
          label="Confirm Password"
          type="password"
          fullWidth
          variant="outlined"
          value={formData.confirmPassword}
          error={error.confirmPassword}
          helperText={error.confirmPassword && error.confirmPassword}
          onChange={handleChange("confirmPassword")}
        />
      </DialogContent>
      <CustomDialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        {loader ? (
          <Button color="primary">
            <CircularProgress />
          </Button>
        ) : (
          <Button onClick={handleSave} color="primary">
            Create
          </Button>
        )}
      </CustomDialogActions>
    </Dialog>
  );
};

export default AddShop;
